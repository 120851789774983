import { FlexCrate } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { ContentSectionFullHeight } from '@client/components/connect-container';
import { LoadingAnimation, VerifyingText } from './verifying-credentials.style';

export type Props = {
  providerName: string;
  complete: boolean;
  timeoutMilliseconds: number;
};

export const VerifyingCredentials = ({
  providerName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  complete,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timeoutMilliseconds,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.verifiying' });
  return (
    <ContentSectionFullHeight centerContent dataAttributes={{ testid: 'verifying-credentials' }}>
      <FlexCrate gap="32px" flexDirection="column" alignItems="center">
        <LoadingAnimation src="/assets/animations/connect.riv" data-testid="rive-animation" />
        <VerifyingText>{t('verifiyingText', { providerName })}</VerifyingText>
      </FlexCrate>
    </ContentSectionFullHeight>
  );
};
