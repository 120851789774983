import { useCallback, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { ProviderForm } from '@client/components/provider-form';
import { Conclusion } from '@client/components/conclusion';
import { assertNotNullable, Organization } from '@arc-connect/schema';
import { Config } from '@client/types/config';
import { ConnectContainer } from '@client/components/connect-container';
import { trpc } from '@client/utils/trpc';
import { useTranslation } from 'react-i18next';
import { CredentialsScreens } from '../credentials/credentials-screens';
import { connectMachine } from './connect';

export interface Props {
  organization: Organization;
  config: Config;
}

export const ConnectScreens = ({ organization, config }: Props): JSX.Element | null => {
  const { t } = useTranslation('connect', { keyPrefix: 'screenTitles' });

  const { client } = trpc.useContext();

  const [connectState, send] = useMachine(() => connectMachine({ client, organization, config }));
  const { credentialsMachine } = connectState.context;

  const title = useMemo(() => {
    // let result = 'default';
    // if we're on the provider screen, return that title
    if (connectState.matches('provider')) {
      return t('provider');
    }
    // if we're in the credentials machine, look to see if we're polling
    if (connectState.matches('credentialsMachine') && credentialsMachine) {
      if (credentialsMachine.getSnapshot()?.matches('polling')) return t('polling');
      // if we're not polling, check whether we're in update or create flow
      return connectState.context.config.verifiedTokenInfo
        ? t('reconnectAccount')
        : t('connectAccount');
    }
    // if we're on the conclusion screen, check the status and return titles conditionally
    if (connectState.matches('conclusion')) {
      return connectState.context.challengeStatus === 'LOGIN_SUCCESS'
        ? t('conclusionSuccess')
        : t('conclusionReceived');
    }
    return t('default');
  }, [connectState, credentialsMachine, t]);

  const previous = useMemo(() => {
    if (credentialsMachine) {
      if (credentialsMachine.getSnapshot()?.can('PREVIOUS'))
        return () => credentialsMachine.send('PREVIOUS');
    } else if (connectState.can('PREVIOUS')) {
      return () => send('PREVIOUS');
    }
  }, [credentialsMachine, connectState, send]);

  const renderScreen = useCallback(() => {
    if (connectState.matches('provider'))
      return (
        <ProviderForm
          initialProviderSearch={connectState.context.providerSearchString}
          pending={connectState.matches('provider.fetchingAcquisitionTemplate')}
          submissionError={connectState.context.acquisitionTemplateError}
          organization={connectState.context.organization}
          apiMode={connectState.context.config.apiMode}
          onSubmit={({ provider, providerSearchString }) =>
            send({
              type: 'SELECT_PROVIDER',
              data: {
                providerSearchString,
                provider,
              },
            })
          }
        />
      );
    if (connectState.matches('credentialsMachine') && credentialsMachine)
      return <CredentialsScreens credentialsMachineRef={credentialsMachine} />;
    if (connectState.matches('conclusion')) {
      return (
        <Conclusion
          status={connectState.context.challengeStatus}
          provider={assertNotNullable(connectState.context.provider)}
          organizationName={organization.displayName || organization.name}
        />
      );
    }
    throw new Error('Unknown state');
  }, [connectState, send, credentialsMachine, organization]);

  return (
    <ConnectContainer
      title={title}
      previous={previous}
      testId={`connect-screens-${config.correlationId}`}
    >
      {renderScreen()}
    </ConnectContainer>
  );
};
