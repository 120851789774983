import { useState, useMemo, useCallback, useEffect } from 'react';

import { debounce } from 'lodash-es';
import { SearchBar } from './debounced-search-bar.style';

export type Props = {
  className?: string;
  initialSearchInput?: string | null;
  onSearch: (searchTerm: string, filterKey?: string, filterValue?: string) => void;
  label: string;
  name: string;
  errorText?: string;
};

export const DebouncedSearchBar = ({
  className,
  initialSearchInput,
  onSearch,
  label,
  name,
  errorText,
}: Props): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>(initialSearchInput || '');

  const debouncedSearch = useMemo(() => debounce(onSearch, 300), [onSearch]);

  useEffect(() => {
    if (initialSearchInput === undefined) {
      setSearchInput('');
    }
  }, [initialSearchInput]);

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch]);

  const handleSearchChange = useCallback(
    (value: string) => {
      // Immediately set search input for user feedback
      setSearchInput(value);
      // Only send to parent once the debounce is done
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <SearchBar
      sticky
      className={className}
      value={searchInput}
      label={label}
      name={name}
      onChange={handleSearchChange}
      errorText={errorText}
    />
  );
};
